import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";

const Banner: React.FC<{ onToggleSettings: () => void; isAuthenticated: boolean }> = ({
  onToggleSettings,
  isAuthenticated,
}) => {
  return (
    <>
      <img id="karla-logo" src="/assets/karla-logo.svg" alt="karla-logo"></img>
      {isAuthenticated && (
        <IconButton disableRipple aria-label="settings" onClick={onToggleSettings}>
          <SettingsIcon></SettingsIcon>
        </IconButton>
      )}
    </>
  );
};

export default Banner;
