import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

type DisplayWarningProps = {
  display_message: { reason: string } | null;
  snackbarHandleClose: () => void;
};

const DisplayWarning = ({ display_message, snackbarHandleClose }: DisplayWarningProps) => {
  const { t } = useTranslation();

  return (
    <Snackbar
      open={true}
      autoHideDuration={5000}
      onClose={snackbarHandleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={snackbarHandleClose} severity="warning" sx={{ width: "100%" }}>
        {t(display_message?.reason ?? "")}
      </Alert>
    </Snackbar>
  );
};

export default DisplayWarning;
