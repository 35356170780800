import axios from "axios";
import { API_ENDPOINTS } from "../utils/constants";
import { LocalStorageHelper, LocalStorageKeys } from "../utils/local-storage-helper";
// import { AnyAction } from 'redux';
// import { ThunkAction } from 'redux-thunk';

type Response = {
  type: string;
  model: {
    id: string;
    org_id: string;
    metadata: object;
    publishedVersion: string;
    settings: {
      datastreams: Array<string>;
      metadata: object;
      rating: string;
      status: string;
      styles: object;
      version_id: string;
    };
  };
  reason?: string;
};

const getModelVersion = async (params: any) => {
  // dispatch({ type: 'SET_IS_LOADING', payload: true });
  try {
    const response = await axios.get<Response>(`${API_ENDPOINTS.STRAACT_FLASK_API}/karla/model/version/get`, {
      params: params,
      headers: {
        Authorization: `Bearer ${params?.auth}`,
      },
    });

    if (response.data && response.data.type === "success") {
      if (response?.data?.model?.settings?.status === "active") {
        const model = response?.data?.model;
        Office.context.roamingSettings.set("karlamail_model", model);
        LocalStorageHelper.setItem(LocalStorageKeys.KARLA_MODEL, model);
        Office.context.roamingSettings.saveAsync(function (result) {
          if (result.status !== Office.AsyncResultStatus.Succeeded) {
            console.error(`Action failed with message ${result.error.message}`);
          } else {
            console.log(`Settings saved with status: ${result.status}`);
          }
        });
        //   dispatch({ type: 'SET_MODEL', payload: response?.data?.model });
      } else {
        console.log("#### MODEL NOT ACTIVE #####", response.data);
        console.log("#### STATUS ####", response?.data?.model?.settings?.status);
      }
    } else if (response.data) {
      console.log("####### FAILED TO FETCH MODEL VERSION ######", response.data);
    }

    if (response.data && response.data.type === "error") {
      // dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
    }
  } catch (error) {
    //   dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
  }

  // dispatch({ type: 'SET_IS_LOADING', payload: false });
};

export default getModelVersion;
