import React, { useState, useEffect, useCallback, useRef } from "react";
import AuthenticationForm from "./security/AuthenticationForm";
import Banner from "./ux/Banner";
import SettingsMenu from "./settings/SettingsMenu";
import MetaData from "./ux/MetaData";
import getModels from "../../api/get-models";
import PrimaryButton from "./mui/PrimaryButton";
import promptAnswerSuggestion from "./../../api/prompt-answer-suggestion";
import DisplayError from "./mui/snackbars/DisplayError";
import DisplaySuccess from "./mui/snackbars/DisplaySuccess";
import DisplayWarning from "./mui/snackbars/DisplayWarning";
import AuthenticationService from "../../api/authentication-service";
import axios from "axios";
import { logoutFromO365, signInO365 } from "../security/graph-api/graph-authenticator";
import Answer from "./answer/Answer";
import Typography from "@mui/material/Typography";
import { LocalStorageHelper, LocalStorageKeys } from "../../utils/local-storage-helper";

const Root: React.FC = () => {
  const [models, setModels] = useState<any[]>([]);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [formVisible, setFormVisible] = useState<boolean>(true);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  const [answerHtml, setAnswerHtml] = useState<string | null>(null);
  const [conversation, setConversation] = useState<any | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState<string | null>(null);
  const [writeAnswerButtonText, setWriteAnswerButtonText] = useState<string>("Write answer suggestion");
  const [graphAuthenticated, setGraphAuthenticated] = useState<boolean>(false);
  const [firstTimeLogin, setFirstTimeLogin] = useState<boolean>(true);
  const [item, setItem] = useState<any | null>(null);

  const accessTokenRef = useRef<string | null>(null);
  const userNameRef = useRef<string | null>(null);

  const authenticationService = useRef(new AuthenticationService());

  const displayError = (error: string) => {
    setError({ reason: error });
    setWarning(null);
    setMessage(null);
  };

  const setUserName = (userName: string) => {
    userNameRef.current = userName;
  };

  const silentGraphSignIn = async (): Promise<boolean> => {
    try {
      const token = await Office.auth.getAccessToken({ allowSignInPrompt: true });
      if (token) {
        setGraphAuthenticated(true);
        LocalStorageHelper.setItem(LocalStorageKeys.MSAL_ACCOUNT_TOKEN, token);
        return true;
      }
    } catch (error) {
      console.log("Error obtaining token:", error);
    }
    return false;
  }

  useEffect(() => {
    const checkFirstTimeLogin = () => {
      const firstTime = LocalStorageHelper.getItem(LocalStorageKeys.FIRST_TIME_LOGIN);
      const karlaAccessToken = Office.context.roamingSettings.get("karla_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ACCOUNT_TOKEN);
      const msalAccessToken = Office.context.roamingSettings.get("msal_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.MSAL_ACCOUNT_TOKEN);

      if(msalAccessToken) {
        setGraphAuthenticated(true)
      }

      if (!firstTime && karlaAccessToken && msalAccessToken) {
        setAuthenticated(true);
        setFirstTimeLogin(false);
        setGraphAuthenticated(true);
        setFormVisible(false);
      }
    };
    checkFirstTimeLogin();
  }, [graphAuthenticated, firstTimeLogin, authenticated]);

  const login = async () => {
    try {
      let token, userName = await signInO365(displayError);
      if (LocalStorageHelper.getItem(LocalStorageKeys.MSAL_ACCOUNT_TOKEN) !== "undefined") {
        setGraphAuthenticated(true);
        const conversationItem = await getConversation();
        setItem(conversationItem);
      }
    } catch (error) {
      displayError("Login failed.");
    }
  };

  const logout = useCallback(async () => {
    await logoutFromO365(setUserName, userNameRef.current, displayError);
  }, []);

  const fetchModels = useCallback(async () => {
    const orgId = Office.context.roamingSettings.get("karlamail_org_id") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ORG_ID);
    const modelsData = await getModels({ org_id: orgId });
    setModels(modelsData);
  }, []);

  const handleAuthenticationCallback = useCallback(async () => {
    setAuthenticated(true);
    LocalStorageHelper.setItem(LocalStorageKeys.FIRST_TIME_LOGIN, false);
    await fetchModels();
    setFormVisible(false);
  }, [fetchModels]);

  const getConversation = useCallback(async (): Promise<any | null> => {
    try {
      const conversationId = Office.context.mailbox.item.conversationId;
      const token = Office.context.roamingSettings.get("msal_access_token") || LocalStorageHelper.getItem(LocalStorageKeys.MSAL_ACCOUNT_TOKEN);
      
      const response = await axios.get(`https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${conversationId}'`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      return response.data?.value?.[0] ?? null;
    } catch (e: any) {
      console.error("Error getting conversation:", e);
  
      // Check if the error is related to unauthorized access (401)
      if (e.response?.status === 401) {
        console.log("Token expired or unauthorized, attempting to refresh token.");
  
        // Attempt to get a new token via silentGraphSignIn
        const isSignedIn = await silentGraphSignIn();
  
        if (isSignedIn) {
          // Retry the request with the new token
          const newToken = LocalStorageHelper.getItem(LocalStorageKeys.MSAL_ACCOUNT_TOKEN);
          try {
            const conversationId = Office.context.mailbox.item.conversationId;
            const retryResponse = await axios.get(`https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${conversationId}'`, {
              headers: { Authorization: `Bearer ${newToken}` },
            });
            return retryResponse.data?.value?.[0] ?? null;
          } catch (retryError) {
            console.error("Retry failed:", retryError);
            return null;
          }
        } else {
          console.error("Silent sign-in failed, unable to get a new token.");
          return null;
        }
      }
  
      // If error is not 401 or if retry fails, return null
      return null;
    }
  }, []);
  

  const handleWriteSuggestion = useCallback(async () => {
    setWriteAnswerButtonText("Hold on...");
    setWarning("Generating answer");
    try {
      const conversation : any = await promptAnswerSuggestion();
      setConversation(conversation);
      setAnswerHtml(conversation?.messages?.at(1).content);
      setWarning(null);
      setMessage("Answer generated successfully");
    } catch (e) {
      displayError(e.message);
    } finally {
      setWriteAnswerButtonText("Write answer suggestion");
    }
  }, []);

  const handleResetAuth = useCallback(() => {
    if (authenticated) {
      Office.context.roamingSettings.remove("authentication_form_api_key");
      Office.context.roamingSettings.remove("karlamail_uid");
      Office.context.roamingSettings.remove("authentication_form_secret_key");
      Office.context.roamingSettings.remove("karla_access_token");
      Office.context.roamingSettings.remove("karlamail_models");
      Office.context.roamingSettings.remove("karlamail_model");
      Office.context.roamingSettings.remove("msal_access_token")
      setModels([]);
      setAnswerHtml(null);
      setConversation(null);
      Office.context.roamingSettings.saveAsync(result => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          setFormVisible(true);
          setAuthenticated(false);
          setSettingsVisible(false);
          setMessage("Authentication has been reset");
        } else {
          displayError("Failed to reset authentication");
        }
      });
    }
    if (graphAuthenticated) {
      logout();
      setGraphAuthenticated(false);
    }
    LocalStorageHelper.clear();
    setFirstTimeLogin(true)
    setGraphAuthenticated(false);
  }, [authenticated, graphAuthenticated, logout]);

  const handleToggleSettings = useCallback(() => {
    setSettingsVisible(prev => !prev);
  }, []);

  const handleRefine = (conversation) => {
    if (conversation) {
      setConversation(conversation);
      setAnswerHtml(conversation?.messages?.at(conversation?.messages?.length - 1).content);
      setMessage( "Successfully refined message");
    } else {
      console.error("Failed to refine answer");
      displayError("Failed to refine answer");
    }
  };

  useEffect(() => {
    if (graphAuthenticated && authenticated) {
      fetchModels();
    }
  }, [graphAuthenticated, authenticated, fetchModels]);

  return (
    <>
      <div id="banner-wrapper">
        <Banner onToggleSettings={handleToggleSettings} isAuthenticated={graphAuthenticated} />
      </div>
      <hr />
      {settingsVisible && (
        <div id="settings-menu">
          <SettingsMenu
            models={models}
            onResetAuth={handleResetAuth}
            graphAuthenticated={graphAuthenticated}
            karlaAuthenticated={authenticated}
          />
        </div>
      )}
      {!graphAuthenticated && authenticated && <MetaData item={item} />}

      {firstTimeLogin && !graphAuthenticated && (
        <div id="graph-authentication-wrapper">
          <div className="authentication-content">
            <p>You are a few steps away from enabling KarlaMail to handle your email replies.</p>
            <p>First you login with the same Microsoft account as the one you are using in Outlook.</p>
            <br />
            <PrimaryButton className="success-btn" text="Login" handleClick={login} />
          </div>
          <div id="authentication-footer-text">
          <span id="authentication-button">
              <Typography variant="subtitle2" sx={{ color: "gray", fontSize: "0.8rem" }}>
                If you are not a customer of ours you can read more about the service at:
                <br />
                <a href="https://getkarla.ai" target="_blank">
                  https://getkarla.ai
                </a>
              </Typography>
            </span>
            <span id="authentication-button">
              <Typography variant="subtitle2" sx={{ color: "gray", fontSize: "0.8rem" }}>
                For any further questions contact us via the information found at:
                <br />
                <a href="https://getkarla.ai/kontakt/" target="_blank">
                  https://getkarla.ai/kontakt/
                </a>
              </Typography>
            </span>
          </div>
        </div>
      )}
      {firstTimeLogin && graphAuthenticated && (
        <AuthenticationForm isVisible={formVisible} onAuthenticated={handleAuthenticationCallback} />
      )}
      {authenticated && !formVisible && (
        <PrimaryButton
          fullWidth
          text={writeAnswerButtonText}
          handleClick={handleWriteSuggestion}
          id="prompt-answer-suggestion"
        />
      )}
      {answerHtml && (
        <div id="suggested-prompt-answer">
          <Answer answerHtml={answerHtml} conversation={conversation} onRefine={handleRefine} />
        </div>
      )}
      <div id="feedback">
          {error && <DisplayError display_message={error} snackbarHandleClose={() => setError(null)}></DisplayError>}
          {!error && warning && (
            <DisplayWarning display_message={{reason: warning}} snackbarHandleClose={() => setWarning(null)}></DisplayWarning>
          )}
          {!error && !warning && message && (
            <DisplaySuccess display_message={{reason: message}} snackbarHandleClose={() => setMessage(null)}></DisplaySuccess>
          )}
        </div>
    </>
  );
};

export default Root;