import { LocalStorageHelper, LocalStorageKeys } from "../utils/local-storage-helper";
import getAuthToken from "./get-auth-token";

class AuthenticationService {
  
  resetAuth = () => {
    Office.context.roamingSettings.remove("authentication_form_api_key");
    Office.context.roamingSettings.remove("authentication_form_secret_key");
    Office.context.roamingSettings.remove("karlamail_uid");
    Office.context.roamingSettings.remove("karla_access_token");
    Office.context.roamingSettings.remove("karlamail_models");
    Office.context.roamingSettings.remove("karlamail_model");

    Office.context.roamingSettings.saveAsync((result) => {
      if (result.status !== Office.AsyncResultStatus.Succeeded) {
        console.error(`Action failed with message ${result.error.message}`);
      } else {
        console.log(`Settings saved with status: ${result.status}`);
      }
    });
  };

  getCachedToken = () => {
    const cached_token = Office.context.roamingSettings.get("karla_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ACCOUNT_TOKEN);
    if (cached_token) {
      return cached_token;
    }
    return null
  }

  checkAuthentication = async () => {
    //

    const ORG_ID = Office.context.roamingSettings.get("karlamail_org_id");
    const ACCESS_TOKEN = Office.context.roamingSettings.get("karla_access_token");

    if (!ORG_ID || !ACCESS_TOKEN) {
      console.log("NO AUTHENTICATION");
      return false;
    }

    if (Object.prototype.toString.call(ACCESS_TOKEN) !== "[object String]") {
      this.resetAuth();
      console.log("Not a string return", ACCESS_TOKEN);
      return false;
    }

    if (ORG_ID && ACCESS_TOKEN) {
      console.log("ALREADY AUTHENTICATED!");
      return true;
    }

    console.log("AUTHENTICATION DETECTED");
    return true;
  };
  //
  automaticAuthentication = async () => {
    const api_key = Office.context.roamingSettings.get("authentication_form_api_key");
    const secret_key = Office.context.roamingSettings.get("authentication_secret_key");
    this.authenticate(api_key, secret_key, () => {}, false);
  };

  authenticate = async (api_key: string, secretKey: string, callback: (boolean) => any , resetToken: boolean) => {
    try {
      if (resetToken) {
        Office.context.roamingSettings.set("authentication_form_api_key", api_key);
        Office.context.roamingSettings.set("authentication_form_secret_key", secretKey);
        Office.context.roamingSettings.remove("karlamail_uid");
        Office.context.roamingSettings.remove("karla_access_token");
      }

      await this.getAuthTokenRequest();
      await Office.context.roamingSettings.saveAsync((result) => {
        if (result.status !== Office.AsyncResultStatus.Succeeded) {
          console.error(`Failed to save settings: ${result.error.message}`);
          callback(false);
          return;
        } else {
          console.log("Settings saved successfully");
        }
      });
      const isAuthenticated = await this.checkAuthentication();
      callback(isAuthenticated);
    } catch (e) {
      console.error("Error during authentication", e.message);
    }
  };

  getAuthTokenRequest = async () => {
    const api_key = Office.context.roamingSettings.get("authentication_form_api_key");
    const secret_key = Office.context.roamingSettings.get("authentication_form_secret_key");
    return await getAuthToken({ api_key, secret_key });
  };
}

export default AuthenticationService;
