import axios from "axios";
import { API_ENDPOINTS } from "../utils/constants";
import jwt from "jwt-decode";
import { LocalStorageHelper, LocalStorageKeys } from "../utils/local-storage-helper";

const getAuthToken = async (params: any) => {
  
  try {
    const response = await axios.get(`${API_ENDPOINTS.STRAACT_FLASK_API}/karla/auth/token`, {
      params: params,
      headers: {
        Authorization: `Bearer ${params.api_key}`,
      },
    });

    if (response.data && response.data.type === "success") {
      const token: any = jwt(response?.data?.token);
      console.log(token)
      if (token) 
        Office.context.roamingSettings.set("karla_access_token", response.data.token);
        Office.context.roamingSettings.set("karlamail_uid", token.creator);
        Office.context.roamingSettings.set("karlamail_org_id", token.org_id);
        LocalStorageHelper.setItem(LocalStorageKeys.KARLA_ACCOUNT_TOKEN, token);
        LocalStorageHelper.setItem(LocalStorageKeys.KARLA_ORG_ID, token.org_id);
        LocalStorageHelper.setItem(LocalStorageKeys.KARLA_UID, token.creator);

        Office.context.roamingSettings.saveAsync((result) => {
          if (result.status !== Office.AsyncResultStatus.Succeeded) {
            console.error(`Action failed with message ${result.error.message}`);
          } else {
            console.log(`Settings saved with status: ${result.status}`);
          }
        });
        return token;
      }
     else if (response.data.type === "error") {
      console.log("Error getting token:", response.data.reason);
    }
  } catch (error) {
    console.error("Error when fetching auth token:", error.message);
  }
};

export default getAuthToken;
