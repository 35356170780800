import axios from "axios";
import { API_ENDPOINTS } from "../utils/constants";
import { LocalStorageHelper, LocalStorageKeys } from "../utils/local-storage-helper";
// import { AnyAction } from 'redux';
// import { ThunkAction } from 'redux-thunk';

const getModels = async (params: any) => {
  // dispatch({ type: 'SET_IS_LOADING', payload: true });

  try {
    const karlaAccessToken = Office.context.roamingSettings.get("karla_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ACCOUNT_TOKEN);

    const response = await axios.get(`${API_ENDPOINTS.STRAACT_FLASK_API}/karla/operations/models/get`, {
      params: params,
      headers: {
        Authorization: `Bearer ${karlaAccessToken}`,
      },
    });

    if (response.data && response.data.type === "success") {
      Office.context.roamingSettings.set("karlamail_models", response?.data?.response);
      LocalStorageHelper.setItem("KARLA_MODELS", response?.data?.response)
      Office.context.roamingSettings.saveAsync(function (result) {
        if (result.status !== Office.AsyncResultStatus.Succeeded) {
          console.error(`Action failed with message ${result.error.message}`);
        } else {
          console.log(`Settings saved with status: ${result.status}`);
        }
      });
      // dispatch({ type: 'SET_MODELS', payload: response?.data?.response });

      return response?.data?.response;
    }

    if (response.data && response.data.type === "error") {
      // dispatch({ type: 'DISPLAY_ERROR', payload: { reason: response.data.reason } });
    }
  } catch (error) {
    console.error("Error when fetching models:", error);
    //   dispatch({ type: 'DISPLAY_ERROR', payload: { reason: error } });
  }

  // dispatch({ type: 'SET_IS_LOADING', payload: false });
};

export default getModels;
