const LocalStorageKeys = {
  MSAL_ACCOUNT_TOKEN: "MSAL_ACCOUNT_TOKEN",
  KARLA_ACCOUNT_TOKEN: "KARLA_ACCOUNT_TOKEN",
  KARLA_ORG_ID: "KARLA_ORG_ID",
  KARLA_UID: "KARLA_UID",
  FIRST_TIME_LOGIN: "FIRST_TIME_LOGIN",
  KARLA_MODEL: "KARLA_MODEL",
  KARLA_MODELS: "KARLA_MODELS"
} as const;

export type LocalStorageKeysType = (typeof LocalStorageKeys)[keyof typeof LocalStorageKeys];

class LocalStorageHelper {
  static setItem<T>(key: LocalStorageKeysType, value: T): void {
    try {
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
    } catch (e) {
      console.error(e);
    }
  }
  static getItem<T>(key: LocalStorageKeysType): T | null {
    try {
      const jsonValue = localStorage.getItem(key);
      const value = jsonValue != null ? JSON.parse(jsonValue) : null;
      return value;
    } catch (error) {
      return null;
    }
  }

  static removeItem(key: LocalStorageKeysType): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }

  static clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(error);
    }
  }

  static getMultipleItems(keys: Array<LocalStorageKeysType>): Record<LocalStorageKeysType, any> | undefined {
    try {
      const result = localStorage.multiGet(keys);
      const final = result.reduce((pre: any, curr: any[]) => {
        const val = curr[1] ? JSON.parse(curr[1]) : null;
        return {
          ...pre,
          [curr[0]]: val,
        };
      }, {} as Record<LocalStorageKeysType, any>);
      return final;
    } catch (error) {
      console.error(error);
    }
  }

}

export { LocalStorageHelper, LocalStorageKeys };
