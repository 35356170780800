import { io, Socket } from "socket.io-client";
import axios from "axios";
import { API_ENDPOINTS } from "../utils/constants";
import { marked } from "marked";
import { LocalStorageHelper, LocalStorageKeys } from "../utils/local-storage-helper";

const renderer = new marked.Renderer();
renderer.link = (href, text) => `<a target="_blank" href="${href}" title="${text}">${text}</a>`;

let answerSuggestionText = "";

type Response = {
  type: string;
  response: {
    conversation: object;
    total_tokens: number;
  };
};

const connectSocket = () => {
  const endpoint = `${API_ENDPOINTS.STRAACT_FLASK_API}/karla/conversation/prompt`;
  const ss: Socket = io(endpoint, {
    transports: ["websocket"],
  });

  return ss;
};

const socket = connectSocket();

if (socket) {
  socket.on("connect", function () {
    socket.emit("join_room", socket.id);
  });
  //
  // Listen for data messages from the Flask server
  socket.on("receive_message", (message: any) => {
    Office.context.mailbox.item.body.setAsync(
      `<span class="karlamail__answer-html">${marked.parse(message[1]?.content)}</span>`,
      { coercionType: Office.CoercionType.Html },
      function (asyncResult) {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          // Body text successfully changeda
        } else {
          console.log("An error occured when changing body text");
          // Handle error
        }
      }
    );
  });

  socket.io.on("reconnect", (attempt: any) => {
    console.log("reconnected", attempt);
  });

  socket.on("connect_error", (error: any) => {
    console.log("connect_error", error);
  });

  socket.on("disconnect", (reason: any) => {
    if (reason === "io server disconnect") {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect();
    }
  });
} else {
  console.log("No socket present");
}

export function createMailWithSuggestion(answer) {
  Office.context.mailbox.item.body.setSelectedDataAsync(
    answer,
    { coercionType: Office.CoercionType.Text },
    function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.error("Failed to insert answer suggestion to mail body");
      } else {
        console.log("Mail body changed successfully");
      }
    }
  );
}

const promptAnswerSuggestion = async () => {
  // Get the email item
  var item = Office.context.mailbox.item;

  await item.saveAsync((result) => {
    if (result.status === Office.AsyncResultStatus.Succeeded) {
    } else {
      console.log("Failed to save item" + result.error.message);
    }
  });

  return new Promise((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result: any) {
      if (result.status === "succeeded") {
        const accessToken = result.value;
        // Use the access token.
        try {
          const conversation = await getCurrentItem();
          resolve(conversation);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      } else {
        // Handle the error.
        console.log("Error when fetching callback token");
        console.log("Error: " + result.error.message);
        reject(new Error(result.error.message));
      }
    });
  });

  function getCurrentItem() {
    // Get the item's REST ID./
    return new Promise((resolve, reject) => {
      Office.context.mailbox.item.getItemIdAsync(async (result: any) => {
        if (result.status === "succeeded") {
          const itemId = result?.value;
          const encodedMessageId = itemId.replace(/%2F/g, "/");

          const token = Office.context.roamingSettings.get("msal_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.MSAL_ACCOUNT_TOKEN) ;
          const getMessageUrl = `https://graph.microsoft.com/v1.0/me/messages/${encodedMessageId}`;
          axios({
            method: "get",
            url: getMessageUrl,
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then(async (response) => {
              const item = response.data;
              const subject = item.subject;

              const htmlString = item.body.content;
              let bodyHtml: string;
              //
              try {
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlString, "text/html");

                if (doc) {
                  bodyHtml = doc.querySelector("body").innerHTML;
                } else {
                  console.error("Failed to parse the HTML");
                  reject("Failed to parse the HTML");
                }
              } catch (error) {
                console.error("An error occurred:", error);
                reject(error);
                return;
              }

              if (!bodyHtml) {
                console.log("Html body is empty");
                reject("Html body is empty");
                return;
              }

              bodyHtml = `Mail subject: ${subject} \n\n` + bodyHtml;
              const model = Office.context.roamingSettings.get("karlamail_model") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_MODEL);

              const initialValue = { id: "", messages: [] };

              const updatedConversation = {
                ...initialValue,
                messages: initialValue?.messages?.concat({ role: "user", content: bodyHtml }),
              };

              const params = {
                conversation_type: "mail",
                conversation: updatedConversation,
                auth: Office.context.roamingSettings.get("karla_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ACCOUNT_TOKEN),
                org: Office.context.roamingSettings.get("karlamail_uid") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_UID),
                org_id: Office.context.roamingSettings.get("karlamail_org_id") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ORG_ID),
                model: model?.id,
                version: model?.version_id,
                room: socket?.id,
                referrer: "outlook",
                knowledge: {
                  type: "GENERAL",
                  current_url: "",
                },
              };
              try {
                const conversation = await promptAnswerSuggestionCall(params);
                resolve(conversation);
              } catch (error) {
                console.error("promptAnswerSuggestionCall error: ", error);
                reject(error);
              }
            })
            .catch(function (error) {
              console.log("An error has occured");
              console.log(error);
              console.log(error.response.data);
              reject(error);
            });
        } else {
          reject(result.error);
        }
      });
    });
  }
};

export async function refineAnswerSuggestionCall(params: any) {
  try {
    const straactAccessToken = Office.context.roamingSettings.get("karla_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ACCOUNT_TOKEN);
    const response: any = await axios.post<Response>(
      `${API_ENDPOINTS.STRAACT_FLASK_API}/karla/conversation/refine`,
      params,
      {
        headers: {
          Authorization: `Bearer ${straactAccessToken}`,
        },
      }
    );

    if (response.data && response.data.type === "success") {
      console.log("Success");
      console.log("response?.data", response?.data?.response);
      const responseText = response?.data?.response?.messages.at(1).content;
      answerSuggestionText = responseText;
      const conversation = response.data.response;
      return conversation;
    }

    if (response.data && response.data.type === "error") {
      console.log("Error", response.data);
    }
  } catch (error) {
    console.log("error", error.message);
  }
}

export async function promptAnswerSuggestionCall(params: any) {
  try {
    const straactAccessToken = Office.context.roamingSettings.get("karla_access_token") ?? LocalStorageHelper.getItem(LocalStorageKeys.KARLA_ACCOUNT_TOKEN);
    const response: any = await axios.post<Response>(
      `${API_ENDPOINTS.STRAACT_FLASK_API}/karla/conversation/prompt`,
      params,
      {
        headers: {
          Authorization: `Bearer ${straactAccessToken}`,
        },
      }
    );

    if (response.data && response.data.type === "success") {
      const responseText = response?.data?.response?.messages.at(1).content;
      answerSuggestionText = responseText;
      const conversation = response.data.response;
      return conversation;
    }
    //
    if (response.data && response.data.type === "error") {
      console.log("Error", response.data);
      throw new Error("Failed to generate answer suggestion");
    }
  } catch (error) {
    console.log("error", error.message);
    throw error;
  }
}

export default promptAnswerSuggestion;
