import React from "react";
import PrimaryButton from "../mui/PrimaryButton";
import ModelSelect from "./ModelSelect";

interface SettingsMenuProps {
  models: any[];
  onResetAuth: () => void;
  graphAuthenticated: boolean;
  karlaAuthenticated: boolean;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ models, onResetAuth, graphAuthenticated, karlaAuthenticated }) => {
  return (
    <>
      <div id="authentication-reset">
        {(karlaAuthenticated || graphAuthenticated) && (
          <PrimaryButton fullWidth className="danger-btn" text="Logout" handleClick={onResetAuth} />
        )}
      </div>

      {karlaAuthenticated && (
        <div id="karlamail-models">
          <label>Select a model:</label>
          <ModelSelect models={models} />
        </div>
      )}

      {(karlaAuthenticated || graphAuthenticated) && <hr />}
    </>
  );
};

export default SettingsMenu;
