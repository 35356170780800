import { LocalStorageHelper, LocalStorageKeys } from "../../../utils/local-storage-helper";

const dialogLoginUrl =
  location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + "/login/login.html";

const checkAccountValidity = async (account: any) => {
  const msalAccountId = account;
  const officeUserId = Office.context.mailbox.userProfile.emailAddress;
  if (msalAccountId !== officeUserId) {
    throw new Error("Account mismatch: MSAL account does not match Office account");
  }
};

export const signInO365 = (displayError) => {
  return new Promise((resolve, reject) => {
    Office.context.ui.displayDialogAsync(dialogLoginUrl, { height: 40, width: 30 }, (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        // Reject the promise with the error message if the dialog fails to open
        reject(`${result.error.code} ${result.error.message}`);
        displayError(`${result.error.code} ${result.error.message}`);
      } else {
        const loginDialog = result.value;

        // Handler for dialog message received
        const messageHandler = async (arg) => {
          const { token, userName } = await processLoginMessage(arg, displayError);
          if (userName === undefined) {
            loginDialog.close();
            return;
          }
          if (token && userName) {
            // Resolve the promise with the token and username when successfully obtained
            resolve({ token, userName });
            loginDialog.close(); // Close the dialog after successful sign-in
          }
        };

        // Handler for dialog events like closing or errors
        const eventHandler = (arg) => {
          processLoginDialogEvent(arg, displayError);
          reject("Dialog closed or an error occurred"); // Reject the promise if there's an error
        };

        // Add event handlers to the dialog
        loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, messageHandler);
        loginDialog.addEventHandler(Office.EventType.DialogEventReceived, eventHandler);
      }
    });
  });
};

// Make sure to adjust the `processLoginMessage` function to return the token and userName

async function processLoginMessage(arg, displayError) {
  // Example logic to extract token and userName from message
  try {
    const message = JSON.parse(arg.message);
    const token = message.token; // Assuming the message contains a token
    const userName = message.userName; // Assuming the message contains a userName
    await checkAccountValidity(userName);
    LocalStorageHelper.setItem(LocalStorageKeys.MSAL_ACCOUNT_TOKEN, token);
    Office.context.roamingSettings.set("msal_access_token", token);
    return { token, userName };
  } catch (error) {
    displayError(error.message);
    return {};
  }
}

const processLoginDialogEvent = (arg, displayError) => {
  processDialogEvent(arg, displayError);
};

let logoutDialog;
const dialogLogoutUrl =
  location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "") + "/logout/logout.html";

const delay = (milliSeconds) => new Promise((resolve) => setTimeout(resolve, milliSeconds));

export const logoutFromO365 = async (setUserName, userName, displayError) => {
  Office.context.ui.displayDialogAsync(dialogLogoutUrl, { height: 40, width: 30 }, async (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      displayError(`${result.error.code} ${result.error.message}`);
    } else {
      logoutDialog = result.value;
      logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, () => processLogoutMessage(setUserName));
      logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, (arg) =>
        processLogoutDialogEvent(arg, displayError)
      );
      await delay(5000); // Wait for dialog to initialize and register handler for messaging.
      logoutDialog.messageChild(JSON.stringify({ userName: userName }));
    }
  });
};

const processLogoutMessage = (setUserName) => {
  logoutDialog.close();
  setUserName("");
};

const processLogoutDialogEvent = (arg, displayError) => {
  processDialogEvent(arg, displayError);
};

const processDialogEvent = (arg, displayError) => {
  switch (arg.error) {
    case 12002:
      displayError(
        "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      displayError("The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.");
      break;
    case 12006:
      console.log("not logged in");
      break;
    default:
      displayError("Unknown error in dialog box.");
      break;
  }
};
